import React from "react";
import { useEngKennaData } from "lang/en/kennametal";
import Kennametal from "sections/Kennametal/Kennametal";

const KennametalPage = () => {
  const data = useEngKennaData();
  return <Kennametal data={data} />;
};

export default KennametalPage;
