import { useKennaPhotos } from "shared/Hooks/kennametal/useKennaPhotos";
import React from "react";
import KennaLogo from "assets/svg/kennametal-logo.svg";
import RotarySell from "assets/image/kennametal/inventory.png";

export const useEngKennaData = () => {
  const photos = useKennaPhotos();
  return {
    metaInfo: {
      title: "Kennametal",
      ogDescription:
        "A global industry leader that provides proven and reliable solutions for your business. Kennametal and Stellram tools are characterized by high precision, long durability, and quick operation. The extraordinary performance and reputation of the producer is a guarantee of success in your company.",
      ogImage: KennaLogo,
    },
    hero: {
      title: "High operating standards",
      subtitle:
        "A global industry leader that provides proven and reliable solutions for your business. Kennametal and Stellram tools are characterized by high precision, long durability, and quick operation. The extraordinary performance and reputation of the producer is a guarantee of success in your company.",
      ogImage: KennaLogo,
    },
    rotary: {
      title: "Rotary tools",
      subtitle:
        "Mills and drills for quick and precise machining. Increased tool life, better surface finish and a perfect match to the material being processed, this is what Kennametal rotary tools are known for.",
      text:
        "The catalog includes: GOdrill, Beyond, Kenna Universal, DUO-LOCK, KenTip, KenTip FS, KSEM, KSEM PLUS, Harvi, GOmill, RSM.",
      src: photos.img1,
      bigImg: true,
      sellInfo: {
        number: 1009,
        caption: (
          <>
            Sold
            <br />
            products
          </>
        ),
        icon: RotarySell,
      },
      links: [
        {
          text: "Face milling",
          linkedCatalogues: [
            {
              title: "DODEKA a leader in advanced face milling",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/DODEKA-lider-w-dzidzinie-zaawansowanego-frezowania-plaszczyzn.pdf",
            },
            {
              title: "Mill 16 cast iron face milling",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/Mill-16-frezowanie-czolowe-zeliwa.pdf",
            },
            {
              title: "KSSM 45 for conventional machining",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSSM-45-do-obrobki-konwencjonalnej.pdf",
            },
            {
              title: "KSOM and KSOM Mini best-in-class face milling cutters",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSOM-i-KSOM-Mini-najlepsze-w-swojej-klasie-frezy-czolowe-do-obrobki-skrawaniem-w-automatach.pdf",
            },
            {
              title: "KSSM8 + Double-sided face milling cutters with 8 edges",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSSM8+Dwustronne-frezy-czolowe-z-8-krawedziami-i-katem-przystawienia-zblizonym-do-90-stopni.pdf",
            },
            {
              title: "Fix-Perfect cutters for roughing and finishing cast iron",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/Fix-Perfect-frezy-do-obrobki-zgrubnej-i-wykanczjacej-zeliwa.pdf",
            },
            {
              title: "HexaCut machining components for the automotive industry",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/HexaCut-obrobka-komponetow-do-branzy-motoryzacyjnej.pdf",
            },
            {
              title: "KSSR Cutters for roughing and finishing cast iron",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSSR-Frezy-do-obrobki-zgrubnej-i-wykanczajacej-zeliwa-z-katem-przylozenia-84-stopnie.pdf",
            },
            {
              title: "KCFM 45 Finishing cast iron milling",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KCFM-45-frezowanie-wykanczajace-zeliwa.pdf",
            },
            {
              title: "MEGA 45 for really heavy machining",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/MEGA-45-do-prawdziwie-ciezkiej-obrobki.pdf",
            },
            {
              title: "MEGA 60 and 90 for really heavy machining",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/MEGA-60-i-90-do-prawdziwie-ciezkiej-obrobki.pdf",
            },
            {
              title: "KBDM PKD face milling cutters",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KBDM-Frezy-czolowe-PKD.pdf",
            },
            {
              title: "KSCM AluMill and KCSM PCD AluMill",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/KSCM-AluMill-i-KCSM-PCD-AluMill.pdf",
            },
            {
              title: "Fix-Perfect for aluminum machining",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-czolowe/Fix-Perfect-do-obrobki-aluminium.pdf",
            },
          ],
        },
        {
          text: "Shape milling",
          linkedCatalogues: [
            {
              title: "Stellram 7792VX series for use at high feed rates",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/Stellram-7792VX-seria-do-stosowania-przy-duzych-wartosciach-posuwu.pdf",
            },
            {
              title:
                "KenFeed 2X double-sided cutters for use at high feed rates",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/KenFeed-2X-dwustronne-frezy-do-stosowania-przy-duzych-wartosciach-posuwu.pdf",
            },
            {
              title: "Double-sided rodeka round plate",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/Rodeka-dwustronna-plytka-okragla.pdf",
            },
            {
              title:
                "KSRM round inserts specially developed for machining titanium and stainless steel",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/KSRM-plytki-okragle-opracowane-specjalnie-do-obrobki-tytanu-i-stali-nierdzewnej.pdf",
            },
            {
              title: "Stellram 7713VR round plates",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/Stellram-7713VR-plytki-okragle.pdf",
            },
            {
              title: "Stellram 5505VX",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/Stellram-5505VX.pdf",
            },
            {
              title: "KDMB, KDMT, KDMS",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/KDMB-KDMT-KDMS.pdf",
            },
            {
              title: "KIPR",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-ksztaltowe/KIPR.pdf",
            },
          ],
        },
        {
          text: "Groove milling",
          linkedCatalogues: [
            {
              title: "KTMS platform for T-slots",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/KTMS-platforma-do-rowkow-typu-T.pdf",
            },
            {
              title: "KVNS platform for milling very narrow grooves",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/KVNS-platforma-do-frezowania-bardzo-waskich-rowkow.pdf",
            },
            {
              title: "SN popular square milling cutters",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/SN-popularne-frezy-z-kwadratowa-plytka.pdf",
            },
            {
              title: "LN cutter system with adjustable width",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/LN-system-frezow-o-regulowanej-szerokosci.pdf",
            },
            {
              title: "KSSM10 and KSSM12",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-rowkow/KSSM10-i-KSSM12.pdf",
            },
          ],
        },
        {
          text: "Shoulder milling",
          linkedCatalogues: [
            {
              title: "Mill4 double-side shoulder milling",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/Mill4-dwustronne-frezowanie-walcowo-czolowe.pdf",
            },
            {
              title: "Mill 1 one-side shoulder milling",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/Mill-1-jednostronne-frezowanie-walcowo-czolowe.pdf",
            },
            {
              title: "Stellram 5720VZ16 series",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/Seria-Stellram-5720VZ16-skrawanie-aluminium-z-wysoka-predkoscia.pdf",
            },
            {
              title: "KSSM Milling perpendicular shoulders",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/KSSM-Frezowanie-odsadzen-prostopadlych.pdf",
            },
            {
              title: "KSSM-KSSP spiral milling of perpendicular shoulders",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/KSSM-KSSP-spiralne-frezowanie-odsadzen-prostopadlych.pdf",
            },
            {
              title: "Stellram 5230VS series",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezowanie-walcowo-czolowe/seria-Stellram-5230VS.pdf",
            },
          ],
        },
        {
          text: "Thread milling",
          linkedCatalogues: [
            {
              title: "Folding thread cutters",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezy-do-gwintow/Skladane-frezy-do-gwintow.pdf",
            },
            {
              title: "KTMD U series",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezy-do-gwintow/Seria-KTMD-U.pdf",
            },
            {
              title: "TMS thread milling system",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Frezy-do-gwintow/TMS-system-frezowania-gwintow.pdf",
            },
          ],
        },
        {
          text: "Cutters rifling",
          linkedCatalogues: [
            {
              title: "Taps",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Gwintowanie/Gwintowniki.pdf",
            },
            {
              title: "Forming Taps",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Gwintowanie/Wyganitaki.pdf",
            },
            {
              title: "Pipe taps",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Gwintowanie/Gwintowniki-rurowe.pdf",
            },
          ],
        },
        {
          text: "Monolithic end mills",
          linkedCatalogues: [
            {
              title: "High performance monolithic cemented carbide cutters",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Monolityczne-frezy-trzpieniowe/Wysokowydajne-monolityczne-frezy-z-weglika-spiekanego.pdf",
            },
            {
              title:
                "Monolithic sintered carbide milling cutters for threading",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Monolityczne-frezy-trzpieniowe/Monolityczne-frezy-z-weglika-spiekanego-do-obrobki-gwintow.pdf",
            },
            {
              title: "Solid carbide end milling cutters for general use",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Monolityczne-frezy-trzpieniowe/Monolityczne-frezy-trzpieniowe-z-weglika-spiekanego-do-ogolnego-przeznaczenia.pdf",
            },
            {
              title: "Duo-Lock modular cutters",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Monolityczne-frezy-trzpieniowe/Frezy-modulowe-Duo-Lock.pdf",
            },
          ],
        },
        {
          text: "Hole finishing",
          linkedCatalogues: [
            {
              title: "Monolithic RMS tools for reaming from cemented carbide",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Monolityczne-narzedzia-RMS-do-rozwiercania-z-weglika-spiekanego.pdf",
            },
            {
              title:
                "RMR, RMB, and RMB-E tools for reaming with soldered blades",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Narzedzia-RMR-RMB-i-RMB-E-do-rozwiercania-z-ostrzami-lutowanymi.pdf",
            },
            {
              title: "Modular reaming tools RHR, RHM and RHM-E",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Modulowe-narzedzia-RHR-RHM-i-RHM-E-do-rozwiercania.pdf",
            },
            {
              title: "Adjustable SIF holder",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Regulowana-oprawka-SIF.pdf",
            },
            {
              title: "RIR and RIQ tools for boring with guide elements",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Narzedzia-RIR-i-RIQ-do-rozwiercania-z-elementem-prowadzacym.pdf",
            },
            {
              title:
                "Romicron boring tool system for high performance machining",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/System-narzedzi-wytaczarskich-Romicron-do-obrobki-wysokowydajnej.pdf",
            },
            {
              title: "ModBORE boring tool system",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/System-narzedzi-wytaczarskich-ModBORE.pdf",
            },
            {
              title: "Custom PCD tools",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Obrobka-wykanczajaca-otworu/Niestandardowe-narzedzia-PCD.pdf",
            },
          ],
        },
        {
          text: "Combined drilling",
          linkedCatalogues: [
            {
              title: "BF combined drilling system",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiercenie-kombinowane/System-wiercenia-kombinowanego-BF.pdf",
            },
            {
              title: "SEFAS combined drilling system",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiercenie-kombinowane/System-wiercenia-kombinowanego-SEFAS.pdf",
            },
          ],
        },
        {
          text: "Modular drills",
          linkedCatalogues: [
            {
              title: "KenTip FS modular drill system",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-modolowe/System-wiertel-modulowych-KenTip-FS.pdf",
            },
            {
              title: "KenTip modular drill system",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-modolowe/System-wiertel-modulowych-KenTip.pdf",
            },
            {
              title: "KSEM modular drill system",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-modolowe/System-wiertel-modulowych-KSEM.pdf",
            },
            {
              title: "KSEM PLUS modular drill system",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-modolowe/System-wiertel-modulowych-KSEM-PLUS.pdf",
            },
          ],
        },
        {
          text: "Carbide monolithic drills",
          linkedCatalogues: [
            {
              title: "GoDrill drills Versatility of materials",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-GoDrill-Wszechstronnosc-materialow.pdf",
            },
            {
              title: "TF drills high machining efficiency",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-TF-wysoka-wydajnosc-obrobki.pdf",
            },
            {
              title: "SGL Beyond drill bits for stainless steel",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-SGL-Beyond-do-stali-nierdzewnej.pdf",
            },
            {
              title: "HP Beyond drills for steel",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-HP-Beyond-do-stali.pdf",
            },
            {
              title: "YPC Beyond drills for machining cast iron materials",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-YPC-Beyond-do-obrobki-materialow-z-zeliwa.pdf",
            },
            {
              title: "SE 4 balds drill bits for steel processing",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-SE-4-LYSINKI-do-obrobki-stali.pdf",
            },
            {
              title: "A long HP Beyond drill with 4 balds",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Dlugie-wiertla-HP-Beyond-z-4-lysinkami.pdf",
            },
            {
              title: "HP Beyond drills for deep holes",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-HP-Beyond-do-glebokich-otworow.pdf",
            },
            {
              title: "HPS Beyond drills for machining aluminum",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-HPS-Beyond-do-obrobki-aluminium.pdf",
            },
            {
              title: "Y-TECH drills machining difficult to cut materials",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-Y-TECH-obrobka-materialow-trudno-skrawalnych.pdf",
            },
            {
              title: "TX drills for machining precision holes",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-TX-do-obrobki-otworow-precyzyjnych.pdf",
            },
            {
              title:
                "SPF drill bits for composite materials (CFRP) and multilayer materials",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-SPF-do-materialow-kompozytowych-(CFRP)-i-materialow-wielowarstwowych.pdf",
            },
            {
              title: "FB drills for holes with a flat bottom",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-FB-do-otworow-z-plaskim-dnem.pdf",
            },
            {
              title: "HP Beyond step drills",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-stopniowe-HP-Beyond.pdf",
            },
            {
              title: "KMH drills for hardened materials",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-KMH-do-materialow-hartowanych.pdf",
            },
            {
              title: "Kenna Universal drill bits",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-Kenna-Uniwersal.pdf",
            },
            {
              title: "NC drilling drills",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-monolityczne-z-weglika-spiekanego/Wiertla-do-nawiercania-NC.pdf",
            },
          ],
        },
        {
          text: "Folding drills",
          linkedCatalogues: [
            {
              title: "Drill Fix, DFR, DFSP, DFT",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-skladane/Drill-Fix-DFR-DFSP-DFT.pdf",
            },
            {
              title: "HTS series folding drill system for deep hole drilling",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-skladane/System-wiertel-skladanych-serii-HTS-do-wiercenia-glebokich-otworow.pdf",
            },
            {
              title: "Drill bit inserts",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-skladane/Plytki-do-wiertel-skladanych.pdf",
            },
            {
              title: "CTR countersink drilling tools",
              url:
                "/catalogs/kennametal/Narzedzia-obrotowe/Wiertla-skladane/Narzedzia-CTR-do-wiercenia-z-poglebianiem.pdf",
            },
          ],
        },
      ],
    },
    turning: {
      title: "Turning tools",
      subtitle:
        "Kennametal turning tools provide excellent chip removal and reduce machine downtime. Their high performance brings better productivity and guarantees work free of deformities.",
      text:
        "In the catalog, among others: Turning inserts - grooving, turning and parting off, K-lock inserts, Kenlock, Fix Perfect system, boring bars and adapters for boring bars, Beyond Evolution, A4 tools, Top Notch.",
      src: photos.img2,
      bigImg: true,
      links: [
        {
          text: "Grooving and trimming",
          linkedCatalogues: [
            {
              title: "Shallow groove machining",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/obrobka-rowkow-i-przycinanie/Top-Notch-obrobka-plytkich-rowkow.pdf",
            },
            {
              title: "Grooving and trimming",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/obrobka-rowkow-i-przycinanie/obrobka-rowkow-i-toczenie-ogolne-A4.pdf",
            },
            {
              title: "Grooving turning and parting",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/obrobka-rowkow-i-przycinanie/Beyond-Evolution-obrobka-rowkow-toczenie-i-przecinanie.pdf",
            },
          ],
        },
        {
          text: "Special use",
          linkedCatalogues: [
            {
              title: "Turning wheeled units",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/specjalne-zastosowanie/toczenie-zespolow-kolowych.pdf",
            },
            {
              title: "K-Lock plates",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/specjalne-zastosowanie/plytki-k-lock.pdf",
            },
            {
              title: "Shape machining",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/specjalne-zastosowanie/obrobka-ksztaltowa-Top-Notch.pdf",
            },
            {
              title: "Fix Perfect",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/specjalne-zastosowanie/Fix-Perfect.pdf",
            },
          ],
        },
        {
          text: "Threading",
          linkedCatalogues: [
            {
              title: "Thread turning Top Notch",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-gwintow/toczenie-gwintow-top-notch.pdf",
            },
            {
              title: "Thread turning with Laydown tools",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-gwintow/toczenie-gwontow-narzedziami-laydown.pdf",
            },
          ],
        },
        {
          text: "ISO turning",
          linkedCatalogues: [
            {
              title: "Boring bars with adjustable vibration damper",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/wytaczaki-z-nastawnym-tlumikiem-drgan.pdf",
            },
            {
              title: "Kennametal tile selection system",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/system-doboru-plytek-kennametal.pdf",
            },
            {
              title: "Tiles from advanced materials",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/plytki-z-materialow-zaawansowanych-oprawki-wytaczaki-wkladki-i-gniazda-pod-glowice-przykrecane.pdf",
            },
            {
              title: "Positive inserts with threaded shank",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/plytki-pozytywowe-z-chwytem-gwintowanym-oprawki-wytaczaki-wkladki-i-gniazda-pod-glowice-przykrecane.pdf",
            },
            {
              title: "Kenloc positive plates",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/plytki-pozytywowe-kenloc-oprawki-wytaczaki-wkladki-i-gniazda-pod-glowice-przykrecane.pdf",
            },
            {
              title: "Kenloc negative plates",
              url:
                "/catalogs/kennametal/narzedzia-do-toczenia/toczenie-iso/plytki-negatywowe-kenloc-oprawki-wytaczaki-wkladki-i-gniazda-pod-glowice-przykrecane.pdf",
            },
          ],
        },
      ],
    },
    stellram: {
      title: "Stellram",
      text:
        "This catalog brings you the latest in the Stellram® brand of indexable milling, solid carbide milling, turning and threading tools — all specifically engineered to enhance overall productivity, even in the most challenging metalworking applications.",
      src: photos.img3,
      bigImg: true,
      links: [
        {
          text: "View catalog",
          url:
            "/catalogs/kennametal/stellram/KMT_Stellram_cutting_tools_metric.pdf",
        },
      ],
    },
    toolsystem: {
      title: "Tooling systems",
      subtitle:
        "These solutions let you work where the machined surface is less accessible, increasing the reach of tools and reducing setup time. Kennametal Tool Systems mean saved time and money, as well as a lot easier work from day to day.",
      text:
        "The catalog includes: KM, DOOSAN, HAAS, HWACHEON, HYUNDAI WIA, Mazak, Mori Siki, OKUMA.",
      src: photos.img4,
      bigImg: true,
      links: [
        {
          text: "View catalog",
          url:
            "/catalogs/kennametal/systemy-narzedziowe/systemy-narzedziowe.pdf",
        },
      ],
    },
    innovations: {
      title: "Kennametal Innovations",
      subtitle:
        "Kennametal’s innovative and patented solutions bring precise and stable machining. New technologies that result in less costly and more efficient work. Maximum productivity means maximum benefits for your company.",
      text:
        "The catalog includes: HPX drill, KenTIP FS modular drilling, eBore modular boring, HARVI I TE monolithic end mills, ER collets for Duo-Lock.",
      src: photos.img5,
      bigImg: true,
      links: [
        {
          text: "Innovations 2021 (1)",
          url:
            "/catalogs/kennametal/innowacje/innowacje-2021-01.pdf",
        },
        {
          text: "Innovations 2020 (1)",
          url:
            "/catalogs/kennametal/innowacje/innowacje-2020-00.pdf",
        },
        {
          text: "Innovations 2020 (2)",
          url:
            "/catalogs/kennametal/innowacje/innowacje-2020-01.pdf",
        },
        {
          text: "Innovations 2020 (3)",
          url:
            "/catalogs/kennametal/innowacje/innowacje-2020-02.pdf",
        },
      ],
    },
  };
};
